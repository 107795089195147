<template>
  <div>
    <div class="card-header-divider">
      <p>История записей</p>
    </div>
    <div class="records_control_wrapper">
      <div class="records_header">
        Записи за <span>{{ recordDate === maxDate ? 'Сегодня' : '' }}</span>
      </div>
      <input type="date" :max="maxDate" v-model="recordDate">
    </div>
    <div v-if="isLoaded">
      <table v-if="currentRecords && currentRecords.length" class="table-sm w-full">
        <thead>
        <tr>
          <th>
            Время записи:
          </th>
          <th>
            Эмоция:
          </th>
          <th>
            Интенсивность:
          </th>
          <th>
            Причина:
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="record in currentRecords" :key="record.id">
          <td>{{ record.recorded_at }}</td>
          <td>{{ record.emotion }}</td>
          <td>{{ record.intensity }}</td>
          <td>{{ record.trigger }}</td>
        </tr>
        </tbody>
      </table>
      <ul v-if="currentRecords && currentRecords.length" class="records_list">
        <li v-for="record in currentRecords" :key="record.id">
          <div class="record_wrapper">
            <p>Время записи: <span>{{ record.recorded_at }}</span></p>
            <p>Эмоция: <span>{{ record.emotion }}</span></p>
            <p>Интенсивность: <span>{{ record.intensity }}</span></p>
            <p>Причина: <span>{{ record.trigger }}</span></p>
          </div>
        </li>
      </ul>
      <div v-else class="no_results">
        <p>Нет записей</p>
      </div>
    </div>
    <div class="records_loader" v-else>
      <mini-preloader/>
    </div>
  </div>
</template>

<script>
import {computed} from "@vue/reactivity";
import {useStore} from "vuex";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import {onBeforeMount, ref, watch} from "@vue/runtime-core";
import MiniPreloader from "@/components/Technical/MiniPreloader.vue";

export default {
  name: "DiaryRecords",
  components: {MiniPreloader, CustomInput},
  setup() {
    const store = useStore();
    const currentRecords = computed(() => store.state.diary.currentDayRecords);
    const isLoaded = ref(false);

    const today = new Date();
    const currentDate = today.getDate();
    const currentMonth = today.getMonth() + 1;
    const maxDate = today.getFullYear() + '-' + (currentMonth < 10 ? '0' + currentMonth : currentMonth) + '-' + (currentDate < 10 ? '0' + currentDate : currentDate);
    const recordDate = ref(maxDate);

    onBeforeMount(() => {
      store.dispatch('diary/getRecordsByDate', {date: maxDate}).then(() => isLoaded.value = true).catch(() => isLoaded.value = true);
    });

    watch(() => [recordDate.value], () => {
      isLoaded.value = false;
      store.dispatch('diary/getRecordsByDate', {date: recordDate.value}).then(() => isLoaded.value = true).catch(() => isLoaded.value = true);
    })

    return {
      recordDate,
      currentRecords,
      maxDate,
      isLoaded
    }
  }
}
</script>

<style scoped lang="scss">
.records_control_wrapper {
  margin: 25px 0;
  display: flex;
  column-gap: 15px;
}

.no_results {
  text-align: center;
  padding: 50px 10px;
}

.records_loader {
  text-align: center;
  padding: 50px 0;
}

.records_list {
  display: none;
  padding: 10px;
  flex-direction: column;
  row-gap: 15px;
}

.records_list li {
  padding: 10px;
  background-color: #edf2f8;
}

.record_wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

@media (max-width: 910px) {
  .table-sm {
    display: none;
  }

  .records_list {
    display: flex;
  }
}
</style>