<template>
  <p>
    <strong>Как пройти тест?</strong> &ndash; На Главной странице по кнопке
    &laquo;Перейти к тесту&raquo; или выбрать доступный тест в меню слева,
    начать тестирование и ответить на все вопросы теста.
  </p>
  <p>
    <strong>Что делать, если не получается войти в личный кабинет?</strong>
    &ndash; восстановить пароль можно по электронной почте по адресу
    <a :href='url'>{{ url }}</a>.
  </p>

  <video class="video_instruction" src="https://lms.way2wei.ru/upload/disk/cb3/cb34174367ec4572ba28f3682104fc99/96a53cceb8ec7698f3790d6dd147584e" controls></video>

  <p>
    <strong>Где можно увидеть отчёт?</strong> &ndash; на Главной странице по
    кнопке &laquo;Получить отчет&raquo;.
  </p>
  <p>
    <strong>Где проходить курсы?</strong> &ndash; на Главной странице в разделе &laquo;Мои курсы&raquo; или выбрать
    вкладку нужного курса в меню слева (вкладка видна, если у вас есть активные доступы к курсам).
  </p>

  <video class="video_instruction" src="https://lms.way2wei.ru/upload/disk/9d9/9d93e9b5af2cc63488f82d28348db59b/186669c4ee80336137d2e9d88cd41d59" controls></video>

  <p>
    <strong
      >Что делать, если вы сотрудник компании и прошли тест, но вам не виден
      отчёт на Главной странице?</strong
    >
    &ndash; спросите ответственного за выдачу отчётов.
  </p>
  <p>
    <strong
      >Что делать, если вы сотрудник компании и должны были получить курс, но
      вкладка с курсом не появилась в меню или выбранный курс не открылся?</strong
    >
    &ndash; спросите ответственного за выдачу курсов.
  </p>

  <h3>Вкладки</h3>
  <p>
    <i class="fad fa-cube"></i>
    <strong>Главная</strong> &ndash; главная страница личного кабинета, здесь можно
    начать тестирование, посмотреть отчёт, сформированный по результатам теста, и доступные для
    прохождения курсы.
  </p>
  <p>
    <img src="@/assets/images/icons/report.svg" alt=""/>
    <strong>Тестирования</strong> &ndash; доступные тестирования ЭИ, после прохождения будет сформирован отчёт.
  </p>
  <p>
    <img src="@/assets/images/icons/course.svg" alt=""/>
    <strong>Курсы</strong> &ndash; доступные для прохождения курсы.
  </p>
</template>

<script>
export default {
  name: "staffFAQ",
  setup() {
    const url = process.env.VUE_APP_NEW_CABINET;

    return {
      url
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./../../assets/css/faq";

.fad.fa-cube {
  margin-right: 5px;
}
</style>