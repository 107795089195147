<template>
  <div class="view-container">
    <div class="card">
      <div class="card-header card-header-divider">
        <div class="card-title">FAQ</div>
      </div>
      <div v-if="profile" class="card-body">
        <div v-if="profile === 'superadmin'">
          Показаны варианты FAQ для всех ролей
        </div>
        <div class="admin-hint" v-if="profile === 'superadmin'">
          Сотрудник
        </div>
        <StaffFAQ
            v-if="
            profile === 'staff' || profile === 'superadmin'
          "
        />
        <div class="admin-hint" v-if="profile === 'superadmin'">
          Юридическое лицо
        </div>
        <AdminFAQ v-if="profile === 'admin' || profile === 'superadmin'" />
        <div class="admin-hint" v-if="profile === 'superadmin'">Физическое лицо</div>
        <PhysicalFAQ
          v-if="profile === 'private_person' || profile === 'superadmin'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import PhysicalFAQ from "./PhysicalFAQ.vue";
import AdminFAQ from "./AdminFAQ.vue";
import StaffFAQ from "./StaffFAQ.vue";

export default {
  components: { PhysicalFAQ, AdminFAQ, StaffFAQ },
  name: "FAQ",
  setup() {
    const store = useStore();
    const profile = computed(() => store.state.profile.profile?.role);
    return { profile };
  },
};
</script>

<style lang="scss" scoped>
.admin-hint {
  border-bottom: 1px solid var(--main-color);
  border-top: 1px solid var(--main-color);
  padding: 10px;
  margin: 20px 0;
}
</style>

<style>
.video_instruction {
  width: 800px;
  height: 600px;
}

@media (max-width: 1180px) {
  .video_instruction {
    width: 100%;
    height: 100%;
  }
}
</style>
