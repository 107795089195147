<template>
  <form action="#" @submit.prevent="submitRecord" class="record_form">
    <div class="fields_wrapper">
      <div class="emotion_spectrum_list_wrapper">
          <div class="spectrum" v-for="(line, index) in emotionSpectrum" :key="index" :class="index">
            <div class="line_wrapper">
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
            </div>
            <div v-for="(emotion) in line" :key="emotion" @click="() => pickEmotion(emotion)" class="emotion_wrapper" :class="{'active': pickedEmotionValue === emotion }">
              <span class="emotion_text">{{ emotion }}</span>
            </div>
          </div>
      </div>
      <div class="bottom_field_wrapper">
        <div class="intensity_wrapper">
          <custom-select-2
              v-if="recordForm.emotion_id"
              :label="'Интенсивность'"
              :options="intensity"
              :labelWidth="65"
              v-model="recordForm.intensity"
              name="intensity"
          />
        </div>
        <div class="trigger_wrapper">
          <custom-select-2
              v-if="recordForm.intensity"
              :label="'Причина'"
              :labelWidth="28"
              :options="triggerList"
              v-model="recordForm.trigger_id"
              name="trigger_list"
          />
        </div>
      </div>
    </div>
    <div v-if="recordForm.emotion_id && recordForm.trigger_id && recordForm.intensity" class="button_wrapper">
      <button :disabled="disabled" class="btn btn-accent">
        Сохранить
      </button>
    </div>
  </form>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "@vue/reactivity";
import {onBeforeMount, reactive, ref} from "@vue/runtime-core";
import {notify} from "@kyvg/vue3-notification";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";

export default {
  name: "DiaryRecordForm",
  components: {CustomSelect2},
  props: {
    availableInterval: {
      type: String,
    }
  },
  setup(props, context) {
    const store = useStore();
    const disabled = ref(false);
    const emotionList = computed(() =>
        store.state.diary.emotionList.map((el) => {
          return {
            id: el.id,
            text: el.value,
          }
        }));
    const triggerList = computed(() =>
        store.state.diary.triggerList.map((el) => {
          return {
            id: el.id,
            text: el.value,
          }
        }));

    const pickedEmotionValue = ref('');

    const orangeSpectrum = ['Интерес', 'Ожидание', 'Настороженность'];
    const yellowSpectrum = ['Безмятежность', 'Радость', 'Восторг'];
    const lightGreenSpectrum = ['Принятие', 'Доверие', 'Восхищение'];
    const greenSpectrum = ['Тревога', 'Страх', 'Ужас'];
    const blueSpectrum = ['Растерянность', 'Удивление', 'Изумление'];
    const darkBlueSpectrum = ['Грусть', 'Печаль', 'Горе'];
    const violetSpectrum = ['Скука', 'Неудовольствие', 'Отвращение'];
    const pinkSpectrum = ['Досада', 'Злость', 'Гнев'];
    const brownSpectrum = ['Смущение', 'Стыд', 'Вина'];

    const emotionSpectrum = {
      orange: orangeSpectrum,
      yellow: yellowSpectrum,
      lightGreen: lightGreenSpectrum,
      green: greenSpectrum,
      blue: blueSpectrum,
      darkBlue: darkBlueSpectrum,
      violet: violetSpectrum,
      pink: pinkSpectrum,
      brown: brownSpectrum,
    }

    const pickEmotion = (value) => {
      let emotion = emotionList.value.find(el => el.text === value);
      recordForm.emotion_id = emotion.id;
      pickedEmotionValue.value = value;
    }

    const intensity = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const createdRecord = computed(() => store.state.diary.createdRecord);

    const today = new Date();
    const date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    const current_date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + date;

    const recordForm = reactive({
      emotion_id: null,
      intensity: null,
      trigger_id: null
    });

    onBeforeMount(() => {
      store.dispatch("diary/getTriggerList");
    });

    const submitRecord = () => {

      if (!recordForm.emotion_id || !recordForm.intensity || !recordForm.trigger_id) {
        notify({
          text: 'Пожалуйста, заполните все поля',
          type: "warning",
        })
      } else {
        disabled.value = true;
        store.dispatch("diary/createRecord", {
          emotion_id: recordForm.emotion_id,
          intensity: recordForm.intensity,
          trigger_id: recordForm.trigger_id,
          record_interval: props.availableInterval,
          record_date: current_date,
        }).then(() => {
          if (createdRecord.value && createdRecord.value.emotion) {
            const message = 'Вы испытывали эмоцию ' + createdRecord.value.emotion + ' с интенсивностью '
                + createdRecord.value.intensity + ' в дату ' + createdRecord.value.date + ' в ' + createdRecord.value.recorded_at;
            notify({
              text: message,
              type: "success",
            });
            store.dispatch('diary/getRecords');
            disabled.value = false;
            context.emit('recordCreated', createdRecord.value.id);
          }
        }).catch(() => {
          disabled.value = false;
        });
      }
    }

    return {
      emotionList,
      emotionSpectrum,
      pickEmotion,
      pickedEmotionValue,
      triggerList,
      intensity,
      recordForm,
      submitRecord,
      disabled
    }
  }
}
</script>

<style scoped lang="scss">
.fields_wrapper {
  width: 600px;
  margin: auto;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.button_wrapper {
  align-self: flex-end;
  padding-bottom: 10px;
}

.record_form {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.bottom_field_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.intensity_wrapper {
  width: 250px;
}

.trigger_wrapper {
  width: 100%;
}

.emotion_spectrum_list_wrapper {
  padding: 50px 0 25px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.spectrum {
  width: 600px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.emotion_wrapper {
  width: 35%;
  padding: 5px;
  cursor: pointer;
  text-align: center;
  position: relative;
}

.emotion_text {
  position: relative;
  z-index: 10;
}

.emotion_wrapper:hover .emotion_text {
  opacity: 0.6;
}

.emotion_wrapper.active {
  color: white;
}

.line_wrapper {
  position: absolute;
  width: 600px;
  display: flex;
  justify-content: space-evenly;
}

.line {
  width: 30px;
  height: 30px;
  border-top: 1px solid white;
  border-right: 1px solid white;
  transform: rotate(45deg);
  top: -1px;
  z-index: 9;
}

@media (max-width: 1050px) {
  .spectrum,
  .line_wrapper,
  .trigger_wrapper {
    width: 500px;
  }
}

@media (max-width: 975px) {
  .fields_wrapper,
  .spectrum,
  .line_wrapper,
  .trigger_wrapper {
    width: 450px;
  }

  .line:nth-child(even) {
    display: none;
  }
}

@media (max-width: 840px) {
  .spectrum,
  .line_wrapper,
  .trigger_wrapper {
    width: 350px;
  }

  .emotion_text {
    font-size: 10px;
  }
}

@media (min-width: 530px) and (max-width: 760px) {
  .spectrum,
  .line_wrapper,
  .fields_wrapper,
  .trigger_wrapper {
    width: 400px;
  }
}

@media (max-width: 450px) {
  .fields_wrapper {
    width: 300px;
  }

  .spectrum,
  .line_wrapper {
    width: 300px;
  }

  .emotion_text {
    font-size: 8px;
  }

  .line:nth-child(even) {
    display: none;
  }

  .trigger_wrapper,
  .intensity_wrapper {
    width: 100%;
  }
}

.orange {
  background: linear-gradient(90deg, rgba(243,237,151,1) 19%, rgba(249,103,22,1) 87%);
}

.yellow {
  background: linear-gradient(90deg, rgba(239,239,179,1) 19%, rgba(244,235,44,1) 87%);
}

.lightGreen {
  background: linear-gradient(90deg, rgba(205,222,182,1) 19%, rgba(95,181,85,1) 87%);
}

.green {
  background: linear-gradient(90deg, rgba(159,206,128,1) 19%, rgba(37,145,82,1) 87%);
}

.blue {
  background: linear-gradient(90deg, rgba(135,205,218,1) 19%, rgba(96,190,205,1) 40%, rgba(29,166,182,1) 98%);
}

.darkBlue {
  background: linear-gradient(90deg, rgba(143,181,211,1) 19%, rgba(83,133,187,1) 52%, rgba(45,107,169,1) 98%);
}

.violet {
  background: linear-gradient(90deg, rgba(180,170,207,1) 19%, rgba(100,85,158,1) 74%);
}

.pink {
  background: linear-gradient(90deg, rgba(240,180,191,1) 19%, rgba(227,74,150,1) 74%);
}

.brown {
  background: linear-gradient(90deg, rgba(197,186,177,1) 19%, rgba(127,104,74,1) 76%);
}
</style>