<template>
  <div class="product-group-table">
    <div v-if="!isPreloaderLoad" class="flex justify-content-center card home-block">
      <div class="card-header group-header">
        <h4 class="group-name">
          {{ route.params.id === 'all' ? 'Все сотрудники' : groupName }}
        </h4>
        <div class="tools group-tools">
          <div class="primary_buttons">
            <button
                v-if="getRight('downloadReports') && reportType"
                @click="getReports"
                class="btn btn-dark"
            >
              Скачать все отчеты
              <i class="fas fa-box-archive"></i>
            </button>
            <router-link v-if="route.params.id !== 'all'"
                         :to="`/group/edit/${route.params.id}`"
                         class="btn btn-accent">Редактировать
            </router-link>
            <button v-if="getRight('groupReferenceProfiles') && route.params.id !== 'all'"
                        class="btn btn-accent"
                    @click="showReferenceProfilesModal = true">
              Эталонный профиль
            </button>
          </div>
          <div class="excel_list">
            <div class="btn btn-accent" @click="showLoadForm = !showLoadForm">
              Загрузить список сотрудников в формате excel
            </div>
          </div>
        </div>
      </div>
      <excel-load-row v-if="showLoadForm" :groupId="route.params.id === 'all' ? null : route.params.id " class="my-10"/>
      <small class="text-left report-name">({{ reportType ? reportType : courseName }})</small>

      <div class="display--flex justify-content-between about-box">
        <div v-if="checkedList.length <= productCountFree" class="display--flex">
          <p>Выбрано <span>{{ checkedList.length }}</span></p>
          <p class="ml-10">Доступно <span>{{ productCountFree }}</span></p>
        </div>
        <div v-if="checkedList.length > productCountFree" class="display--flex align-item-center ml-10">
          <img src="../../../src/assets/images/warning.svg" alt="warning"/>
          <span class="warning-text ml-10">
            В наличии {{ productCountFree + " " + morph(productCountFree, route.params.product) }}.
            Требуется для выдачи дополнительно {{
              checkedList.length - productCountFree + " " + morph((checkedList.length - productCountFree), route.params.product)
            }}.
          </span>
          <button class="btn btn-w ml-10" @click="showConsultModal">Оформить заказ</button>
        </div>
      </div>
      <div class="card-body" v-if="groupUsers && !isPreloaderLoad">
        <div class="display--flex align-item-center justify-content-between page_count_block">
          <div class="options-ag display--flex align-item-center">
            <div class="select-count">
              <custom-select
                  :labelWidth="40"
                  name="rowsCount"
                  v-model="countOnPage"
                  label="Кол-во пользователей на странице"
                  :options="[5, 10, 15, 20, 50, 75]"
              />
            </div>
            <control-visible-columns :name="'group-report'" :hideColumns="hideColumns"/>
            <div @click="showFilter = !showFilter" class="filter_button">
              <span role="button">{{ showFilter ? 'Скрыть фильтры' : 'Показать фильтры' }}</span>
            </div>
          </div>
          <div class="analytic_button">
            <router-link v-if="showAnalyticsBtn"
                         to="/analytics"
                         class="btn btn-accent">Аналитика
            </router-link>
          </div>
        </div>
        <FilterBlock v-if="showFilter" :group="route.params.id"
                     :product="route.params.product"
                     :isTest="isTestProductPage"
                     :key="route.params.id + route.params.product"
                     @loading="loaded = false"
                     @loaded="loaded = true"
        />
        <div v-if="productGroup">
          <div class="loader" v-if="!loaded">
            <preloader/>
          </div>
          <div v-if="loaded">
            <base-table
                v-if="shownData.length"
                :columns="tableFields.value"
                :rows="shownData"
                :checkedList="checkedList"
                :rowSelect="onRowSelected"
                :hiddenColumns="hiddenColumns"
                @sort="(data) => {
                  sortValue = data.sortValue;
                  sortDirection = data.direction;
                }"
            />

            <table-pagination
                v-if="shownData.length"
                :key="updatePagination"
                :totalPages="1"
                :countOnPage="countOnPage"
                :total="rowData.length"
                :count="countOnPage > shownData.length ? shownData.length : countOnPage"
                @changePage="changePage"
            />
            <div v-if="!shownData.length">
              <div class="not_found">Нет подходящих результатов</div>
            </div>
          </div>
        </div>
        <div v-else class="table-preloader">
          <preloader/>
        </div>
      </div>
      <footer>
        <div class="footer__box">
          <router-link
              :to="'/staff/edit' + (route.params.id === 'all' ? '' : `/${route.params.id}`)"
              class="btn btn-w footer__box-btn">Добавить сотрудника
          </router-link>
          <div v-show="!isPassedTest">
            <button :disabled="!checkedList.length || checkedList.length > productCountFree"
                    class="btn btn-w footer__box-btn"
                    @click="showModalAppened">Выдать доступ
            </button>
            <button :disabled="!checkedList.length"
                    class="btn btn-w footer__box-btn"
                    @click="showModalRemove">Отменить доступ
            </button>
            <button :disabled="!checkedList.length"
                    v-if="isTestProductPage"
                    class="btn btn-w footer__box-btn"
                    @click="remindAboutTest">Напомнить
            </button>
          </div>
          <div v-show="isPassedTest">
            <button :disabled="!isPassedTest"
                    v-if="isTestProductPage"
                    class="btn btn-w footer__box-btn"
                    @click="allowViewReport(true)">Открыть доступ к отчету
            </button>
            <button :disabled="!isPassedTest"
                    v-if="isTestProductPage"
                    class="btn btn-w footer__box-btn"
                    @click="allowViewReport(false)">Закрыть доступ к отчету
            </button>
            <button :disabled="!isPassedTest"
                    v-if="isTestProductPage"
                    class="btn btn-w footer__box-btn"
                    @click="downloadLastReport">Скачать отчет
            </button>
          </div>
          <Popper v-if="route.params.id !== 'all'" placement="top-start" arrow class="products-popper">
            <button class="btn btn-w footer__box-btn">Другое</button>
            <template #content>
              <ul>
                <li class="display--flex justify-content-between">
                  <router-link
                      to="/group/edit"
                      class="btn btn-accent"
                  >
                    <span class="navbar-sec__link-subgroup-name">Создать новую группу</span>
                  </router-link>
                </li>
                <li class="display--flex justify-content-between">
                  <router-link :to="`/group/edit/${route.params.id}`"
                               class="btn btn-accent">Добавить в существующую группу
                  </router-link>
                </li>
              </ul>
            </template>
          </Popper>
        </div>
      </footer>
      <quotas-modal v-if="isShowModal" @close-modal="isShowModal = false" :checkedList="checkedList"
                    :appenedQuotas="appenedQuotas" :productOptions="productOptions"
      />
      <course-popup/>
      <pop-up-form title="Оформить заказ"
                   v-if="openConsultModal"
                   @closeModal="openConsultModal = false"/>
      <reference-profiles-modal
         v-if="showReferenceProfilesModal"
         @close="showReferenceProfilesModal = false"
      />
    </div>
  </div>
</template>

<script>

import {useRoute} from "vue-router";
import {useStore} from "vuex";
import {computed, onBeforeMount, reactive, watch} from "@vue/runtime-core";
import reportTranslate from "@/mixins/dictionaries/reportTranslate";
import {ref} from "@vue/reactivity";
import Preloader from "@/components/Technical/Preloader.vue";
import QuotasModal from "./QuotasModal.vue";
import TablePagination from "@/components/OtherElements/TablePagination.vue";
import ControlVisibleColumns from "@/components/OtherElements/ControlVisibleColumns.vue";
import {getTestStatus} from "@/feature/group-control/utils/groupPassingUtils";
import GroupTable from "../../feature/group-control/tables/GroupTable.vue";
import Popper from "vue3-popper";
import CoursePopup from "@/feature/group-control/popups/CoursePopup.vue";
import PopUpForm from "@/components/Popups/PopUpForm.vue";
import CustomSelect from "@/components/Forms/Fields/CustomSelect.vue";
import useCheckRights from "@/mixins/useCheckRights";
import {notify} from "@kyvg/vue3-notification";
import ExcelLoadRow from "@/feature/users/ExcelLoadRow.vue";
import FilterBlock from "@/components/Tables/FilterBlock.vue";
import {getCourseStatus} from "@/feature/group-control/utils/coursePassingUtils";
import BaseTable from "@/components/Tables/BaseTable.vue";
import sortData from "@/mixins/sortData";
import ReferenceProfilesModal from "@/views/ProductGroupReport/ReferenceProfilesModal.vue";

export default {
  components: {
    ReferenceProfilesModal,
    BaseTable,
    FilterBlock,
    ExcelLoadRow,
    CustomSelect,
    PopUpForm,
    CoursePopup,
    Popper,
    ControlVisibleColumns,
    TablePagination,
    QuotasModal,
    Preloader,
    GroupTable,
  },
  name: "product-group-report",

  setup() {
    const route = useRoute(),
        store = useStore(),
        {getRight} = useCheckRights(),
        isPreloaderLoad = ref(false),
        showAllUsers = ref(false),
        reportType = ref(null),
        groupName = ref(null),
        checkedList = ref([]),
        columnsName = ref(null),
        productCountFree = ref(0),
        productCountIssued = ref(0),
        productCountFinished = ref(0),
        isShowModal = ref(false),
        showFilter = ref(false),
        appenedQuotas = ref(false),
        groupUsers = ref([]),
        productOptions = ref([]),
        testOptions = ref([]),
        openConsultModal = ref(false),
        courseName = ref(null),
        productGroup = computed(() => store.state.group.productGroup),
        showAnalyticsBtn = computed(() => route.params.product === 'standard' && productCountFinished.value !== 0 || route.params.product === 'full' && productCountFinished.value !== 0),
        userQuotas = computed(() => store.state.company.userQuotas),
        isTestProductPage = computed(() => route.params.product === "standard" || route.params.product === "full" || route.params.product === "empty"),
        loaded = ref(true);

    const showLoadForm = ref(false);
    const showReferenceProfilesModal = ref(false);

    const countOnPage = ref(parseInt(localStorage.getItem("countOnPagePassings")) || 10)

    const rowData = computed(() => {
      return groupUsers.value.map((user) => {
        return {
          id: user.id,
          name: user.name,
          status: isTestProductPage.value ? getTestStatus(user) : (user.course_passing ? getCourseStatus(user.course_passing) : 'Не выдан'),
          expiration_date: user.course_passing ? user.course_passing?.expiration_date : null,
          finished_tests: user.finished_tests ?? [],
          passingDate: user.last_success_test_passing_date ? user.last_success_test_passing_date : '',
          class: user.trajectories && user.course_passings ? [user.course_passings, user.trajectories] : "",
          testOptions: testOptions.value,
          coursePassing: user.course_passing,
          report: user.test_passings
              ? reportTranslate[user.test_passings.report_type]
              : "",
          code: user.test_passings ? user.test_passings.code : "",
          is_course_active: !isTestProductPage.value ? user.course_passing?.is_active : null,
        }
      })
    })

    const showModalAppened = () => {
      isShowModal.value = true
      appenedQuotas.value = true
    }
    const showModalRemove = () => {
      isShowModal.value = true
      appenedQuotas.value = false
    }

    const isPassedTest = computed(() => {
      if (checkedList.value.length === 0) {
        return false;
      }

      return checkedList.value.every((checkedId) => {
        const user = rowData.value.find((row) => row.id === checkedId);
        return user?.status === 'Тест завершен';
      });
    });

    const allowViewReport = (status) => {
      const codes = checkedList.value.map((checkedId) => {
        const user = rowData.value.find((row) => row.id === checkedId);
        return user?.finished_tests.map((test) => test.code) || [];
      }).flat();

      store.dispatch("group/toggleAllowViewReport", {
        codes: codes,
        status: status,
      });
    }

    const downloadLastReport = () => {
      const codes = checkedList.value.map((checkedId) => {
        const user = rowData.value.find((row) => row.id === checkedId);
        if (!user?.finished_tests || user.finished_tests.length === 0) {
          return null;
        }
        const latestTest = user.finished_tests.reduce((latest, current) => {
          return new Date(current.finished_at) > new Date(latest.finished_at) ? current : latest;
        });
        return latestTest.code;
      }).filter((code) => code !== null);

      if (!codes.length) {
        notify({
          type: "warning",
          title: "Нет доступных для скачивания отчетов",
        });
        return;
      }

      store.dispatch("group/getReportsArchive", {
        group: route.params.id,
        name: groupName.value,
        codes: codes,
      });
    }

    const showConsultModal = () => {
      openConsultModal.value = !openConsultModal.value
    }

    const onRowSelected = (id, selectAll) => {
      const isInclude = checkedList.value.includes(id);
      if (id && isInclude) {

        if (!selectAll) {
          checkedList.value = checkedList.value.filter(
              (currentId) => currentId !== id
          );
        }
      } else {
        checkedList.value.push(id);
      }
    };

    const morph = (int, product, array) => {
      if (product === "standard" || product === "full") {
        return (array = array || ['тест', 'теста', 'тестов']) && array[(int % 100 > 4 && int % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(int % 10 < 5) ? int % 10 : 5]];
      } else if (product === "trajectory") {
        return (array = array || ['траектория', 'траектории', 'траекторий']) && array[(int % 100 > 4 && int % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(int % 10 < 5) ? int % 10 : 5]];
      } else if (product === "references") {
        return (array = array || ['эталонный профиль', 'эталонных профиля', 'эталонных профилей']) && array[(int % 100 > 4 && int % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(int % 10 < 5) ? int % 10 : 5]];
      } else {
        return (array = array || ['курс', 'курса', 'курсов']) && array[(int % 100 > 4 && int % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(int % 10 < 5) ? int % 10 : 5]];
      }
    }

    onBeforeMount(() => {
      setColumns();
      store.dispatch("group/getProductGroup", {product: route.params.product, id: route.params.id})
      store.dispatch("company/getUserQuotas").then(() => {
        for (let test in userQuotas.value.test_passings) {
          productOptions.value.push({
            id: test,
            name: reportTranslate[test],
            countFree: userQuotas.value.test_passings[test].free,
            countIssued: userQuotas.value.test_passings[test].issued,
          })
          testOptions.value.push({
            id: test,
            text: reportTranslate[test],
          })
        }
        userQuotas.value.course_passings?.total.forEach((course) => {
          productOptions.value.push({
            id: course.course_id,
            name: course.name,
            countFree: userQuotas.value.course_passings?.free.find((element) => element.course_id == course.course_id)?.count || 0,
            countIssued: userQuotas.value.course_passings?.issued.find((element) => element.course_id == course.course_id)?.count || 0
          })
        })
        if (userQuotas.value.reference_profiles) {
          productOptions.value.push({
            id: "reference_profiles",
            name: "Эталонный профиль",
            countFree: userQuotas.value.reference_profiles.count
          })
        }
        if (userQuotas.value.trajectories) {
          productOptions.value.push({
            id: "trajectory",
            name: "Траектории",
            countFree: userQuotas.value.trajectories.count
          })
        }
      })
    });

    const getReports = () => {
      const codes = [];
      rowData.value.forEach((el) => {
        if (el.status === "Тест завершен" || el.reportLink) {
          codes.push(el.code);
        }
      });
      if (!codes.length) {
        notify({
          type: "warning",
          title: "Нет доступных для скачивания отчетов",
        });
        return;
      }
      store.dispatch("group/getReportsArchive", {
        group: route.params.id,
        name: groupName.value,
        codes,
      });
    };

    const remindAboutTest = () => {
      store.dispatch("group/remindAboutTest", {
        id: route.params.id,
        staffIds: checkedList.value,
      })
    }

    const setColumns = () => {
      if (isTestProductPage.value) {
        tableFields.value = testFields;
      } else {
        tableFields.value = courseFields;
      }
    }

    const pageDataStart = ref(0);
    const updatePagination = ref(false);
    const totalResults = ref();
    const sortValue = ref('');
    const sortDirection = ref('');

    let tableFields = reactive({
      value: []
    });

    const testFields = [
      {
        field: "check",
        headerName: "",
        checkAll: true,
      },
      {
        field: "name",
        headerName: "Имя",
        sortable: true,
        link: true,
        dest: "staff",
        res: "edit",
      },
      {
        field: "status",
        headerName: "Статус",
        isTest: true,
      },
      {
        field: "passingDate",
        headerName: "Дата прохождения",
        wrapText: true,
      },
      {
        field: "class",
        headerName: "Обучение",
      },
      {
        field: "buttonPass",
        headerName: "",
      },
    ];

    const courseFields = [
      {
        field: "check",
        headerName: "",
        checkAll: true,
      },
      {
        field: "name",
        headerName: "Имя",
        sortable: true,
      },
      {
        field: "status",
        headerName: "Статус",
      },
      {
        field: "buttonPass",
        headerName: "",
      }
    ];

    const shownData = computed(() => {

      let data = rowData.value ?? [];

      if (data.length) {

        if (sortValue.value) {
          data = sortData(data, sortValue.value, sortDirection.value);
        } else {
          data = sortData(data, 'id', 'down');
        }

        data = data.slice(pageDataStart.value, pageDataStart.value + countOnPage.value);
      }

      return data;
    });

    let hiddenColumns = ref([]);

    const hideColumns = (columns) => {
      hiddenColumns.value = [];
      for (const [key, value] of Object.entries(columns)) {
        if (!value) {
          hiddenColumns.value.push(key);
        }
      }
    }

    const changePage = (page) => {
      pageDataStart.value = (page - 1) * countOnPage.value;
    }

    watch(
        () => [route.params.id, route.params.product],
        () => {
          if (route.params.id) {
            loaded.value = false;
            store.dispatch("group/getProductGroup", {
              product: route.params.product,
              id: route.params.id
            }).then(() => loaded.value = true);
            checkedList.value = []
            totalResults.value = 0;
            pageDataStart.value = 0;
            updatePagination.value = !updatePagination.value;
            setColumns();
          }
        }
    )

    watch(countOnPage, () => {
      localStorage.setItem("countOnPagePassings", countOnPage.value);
      countOnPage.value = parseInt(countOnPage.value);
    });

    watch(isShowModal, () => {
      store.dispatch("group/getProductGroup", {product: route.params.product, id: route.params.id})
      if (isShowModal.value === false) {
        checkedList.value = []
      }
    });

    watch(productGroup, () => {
      courseName.value = userQuotas.value?.course_passings.total.find(course => course.course_id === route.params.product)?.name
      groupUsers.value = productGroup.value.quotas.users
      groupName.value = productGroup.value.quotas.group_name
      productCountFinished.value = productGroup.value.quotas.total?.finished
      productCountFree.value = productGroup.value.product_count?.free
      productCountIssued.value = productGroup.value.product_count?.issued
      reportType.value = route.params.nameProduct ? route.params.nameProduct : reportTranslate[route.params.product]

      totalResults.value = 0;
      pageDataStart.value = 0;
      updatePagination.value = !updatePagination.value;
    })

    watch(
        () => [
          sortDirection.value,
          sortValue.value,
          countOnPage.value,
        ],
        () => {
          totalResults.value = 0;
          pageDataStart.value = 0;
          updatePagination.value = !updatePagination.value;
        },
    )

    return {
      isPreloaderLoad,
      showAllUsers,
      reportType,
      groupName,
      groupUsers,
      checkedList,
      productCountFree,
      productCountIssued,
      route,
      isShowModal,
      showModalAppened,
      showModalRemove,
      appenedQuotas,

      rowData,

      onRowSelected,
      countOnPage,
      columnsName,
      productOptions,
      morph,
      openConsultModal,
      showConsultModal,
      productCountFinished,
      showAnalyticsBtn,
      productGroup,
      courseName,
      getRight,
      getReports,
      isTestProductPage,
      remindAboutTest,
      showLoadForm,
      showFilter,
      loaded,
      hideColumns,
      sortValue,
      sortDirection,
      pageDataStart,
      updatePagination,
      tableFields,
      changePage,
      shownData,
      showReferenceProfilesModal,
      hiddenColumns,
      allowViewReport,
      isPassedTest,
      downloadLastReport
    }
  }
}
</script>
<style lang="scss">
.product-group-table {
  .ag-body-viewport {
    min-height: 360px;
  }
}
</style>

<style scoped lang="scss">

.bg-white {
  background-color: #ffffff;
}

.group-tools {
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 800px) {
    align-items: flex-start;
  }
}

.group-header {
  @media (max-width: 425px) {
    flex-direction: column;
  }
}

.group-name {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px;
  @media (max-width: 425px) {
    padding-bottom: 10px;
  }
}

.primary_buttons {
  display: flex;
  column-gap: 10px;

  @media (max-width: 800px) {
    flex-direction: column;
    row-gap: 15px;
  }
}

.report-name {
  font-size: 14px;
  font-style: normal;
  font-weight: 200;
}

.about-box {
  &__text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0 5px;
  }
}

.btn-add {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 84%;
}

.form-group {
  min-width: 350px;
}

.clear-btn {
  margin-left: 15px;
}

.sort-btn {
  height: 45px;
  width: 45px;
  margin-left: 10px;
  margin-top: 10px;
  font-size: 17px;

  &::before {
    content: "\f161";
    font-family: "Font Awesome 5 Pro";
    font-size: 17px;
  }
}

.mobile-sort {
  display: none;
}

.sort-value {
  padding-left: 40px;
  position: relative;

  &.active::before {
    content: "\f00c";
    position: absolute;
    left: 15px;
    top: 6px;
    font-family: "Font Awesome 5 Pro";
  }
}

.sort-line {
  border-bottom: 1px solid var(--cream-color);
  padding-bottom: 10px;
  margin-top: 15px;

  .clear-sort {
    padding: 0;

    img {
      height: 20px;
      width: 20px;
    }
  }

  .sort-text.active {
    font-weight: 500;
    color: var(--main-color);
  }
}

footer {
  background-color: #2CC185;
  position: sticky;
  bottom: 62px;
}

.footer {
  &__box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px;
  }

  &__box-btn {
    margin: 5px;
  }

  @media (max-width: 800px) {
    &__box-btn {
      font-size: 13px;
    }
  }

  &__counter {
    color: white;
    padding-left: 21px;
    @media (max-width: 1150px) {
      display: none;
    }
  }

  &__counter_showOff {
    display: none;
  }

}

.warning-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #FF5C00;
  @media (max-width: 970px) {
    font-size: 14px;
  }
}

.ml-auto {
  margin-left: auto;
}


.btn-bg-white {
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.btn-bg-white:hover {
  color: #575757;
}


@media (max-width: 1540px) {
  .staff-filters {
    flex-direction: column;
  }

  .clear-btn {
    align-self: flex-end;
    margin-top: 15px;
    margin-right: 15px;
  }
  .form-group {
    min-width: 100%;
  }
}

@media (max-width: 970px) {
  .sort-line {
    display: none;
  }
  .mobile-sort {
    display: flex;
  }
}

@media (max-width: 768px) {
  .options-ag {
    display: block;
  }
}

.filter_button {
  cursor: pointer;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
}

.filter_button:hover {
  color: #00e287;
}

.not_found {
  text-align: center;
  padding: 135px 0;
}

.loader {
  padding: 100px 0;
  text-align: center;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.analytic_button {
  margin-left: auto;
}

@media (max-width: 520px) {
  .page_count_block {
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .analytic_button {
    order: -1;
  }
}
</style>
