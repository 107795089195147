export const getTestStatus = (staff) => {
    if (staff.test_passings) {
        if (staff.test_passings.length === 0) {
            if (staff.active_test_passing) {
                const reportNames = {
                    "standard": "стандартным",
                    "full": "полным"
                };
                const name = reportNames[staff.active_test_passing.report_type] || '';
                return `Выдано тестирование с ${name} отчетом`
            } else {
                return "Тест не выдан";
            }
        } else {
            const status = staff.test_passings.status;
            if (
                status === "questionnaire" ||
                status === "instruction" ||
                status === "expired" ||
                status === "new"
            ) {
                return "Тест не пройден";
            } else if (status === "in_progress") {
                return "В процессе прохождения";
            } else {
                return "Тест завершен";
            }
        }
    }
};
